@import '@/styles/variables/mixins/media-queries';
@import '@/styles/variables/mixins/fonts/buttons';
@import '@/styles/variables/mixins/fonts/headlines';
@import '@/styles/variables/mixins/fonts/caption';

@mixin dotted($line) { // многоточие
  display: block;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: $line;
  text-overflow: ellipsis;
  word-wrap: break-word;
  white-space: normal;
}

.event_banner > a {
  display: block;
  height: 100%;
  margin-bottom: 0;
}

.event_banner {
  width: 330px;
  height: 525px;
  position: relative;
  border-radius: 12px;
  box-shadow: 0 0 6px 0 rgba(116, 116, 116, 0.25);

  .event_banner_img {
    position: relative;
    width: 100%;
    height: 330px;
    border: none;
    img {
      border-radius: 12px;
      object-fit: cover;
    }
  }

  .event_banner_gradient {
    height: 102px;
    width: 100%;
    background: linear-gradient(rgba(0, 133, 197, 0.00), #0085C5);
    position: absolute;
    bottom: 222px; //195
    z-index: 1;
  }

  .event_banner_text_wrapper {
    height: 223px;
    width: 100%;
    padding: 0 20px 20px;
    border: none;
    border-radius: 0 0 12px 12px;
    position: absolute;
    bottom: 0;
    background: linear-gradient(0deg, #0085C5 0%, #0085C5 100%);

    .header {

      h4 {
        color: rgba(255, 255, 255, 1);
        margin-bottom: 12px;

        @include --bzr-h4();
        @include dotted(2);
      }
    }

    .description_block {
      display: flex;
      gap: 8px;
      flex-direction: column;

      .price {
        display: flex;
        height: 24px;
        padding: 4px 8px;
        justify-content: center;
        align-items: center;
        gap: 4px;
        border: none;
        border-radius: 12px;
        background-color: #47C612;
        width: fit-content;
        color: rgba(255, 255, 255, 1);
        svg {
          width: 9px;
          height: 16px;
        }
      }

      .date {
        display: flex;
        gap: 4px;
        align-items: center;

        svg {
          width: 24px;
          height: 24px;
          color: white;
        }

        p {
          color: white;
          @include --bzr-caption-medium-fontsize(14px);
        }
      }
    }

    .event_banner_description {
      color: rgba(255, 255, 255, 1);
      @include --bzr-caption-regular();
    }
  }

  .event_banner_swiper {
    width: 100%;
    height: 6px;
    display: flex;
    gap: 4px;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 0;
    bottom: 20px;

    span {
      display: block;
    }
  }
}

@include bzr-breakpoint('tablet') {
  .event_banner {
    width: 231px;
    height: 231px;
    border-radius: 12px;

    .event_banner_img {
      height: 231px;
      border-radius: 12px;

      img {
        border-radius: 12px;
      }
    }

    .event_banner_gradient {
      position: relative;
      bottom: 102px;
      border-radius: 0 0 12px 12px;
    }

    .event_banner_text_wrapper {
      background: none;
      z-index: 1;
      width: 100%;
      height: 100%;
      padding: var(--bzr-spacing-small);

      .header {
        height: 164px;
        display: flex;
        align-items: end;

        h4 {
          font-size: 18px;
          margin: 0;
          @include dotted(1);
        }
      }

      .description_block {

        .price {
          position: absolute;
          top: 12px;
        }

        .date {
          position: absolute;
          top: 179px;
        }
      }

      .event_banner_description {
        display: none;
      }
    }
  }

  .event_banner_swiper {
    z-index: 1;
  }
}

@include bzr-breakpoint('mobile') {
  .event_banner {
    width: 171px;
    height: 171px;
    border-radius: 12px;
    position: relative;

    a {
      display: block;
      height: 171px;
    }

    .event_banner_img {
      height: 171px;
    }

    .event_banner_gradient {
      height: 81px;
      bottom: 81px;
    }

    .event_banner_text_wrapper {
      .header {
        height: 114px;

        h4 {
          font-size: 14px;
        }
      }

      .description_block {
        .date {
          top: 132px;

          p {
            font-size: 11px;
          }

          svg {
            width: 16px;
            height: 16px;
          }

        }
      }
    }

    .event_banner_swiper {
      bottom: 12px;
    }
  }
}
